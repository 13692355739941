// @flow

import React, { Suspense, useEffect } from 'react'
import { useQueryLoader } from 'react-relay'

import { useCurrentUserRoles } from 'react-ui/hooks/useCurrentUserRoles'
import { Box, FlexContainer, FlexItem, LoadingSpinner } from 'care-ui'

import { type QueryVariableType } from '../../hooks/useInsights'
import { query as clinicianLoginsQuery } from '../../queries/ClinicianLoginsInsightsQuery'
import { query as individualLoginsQuery } from '../../queries/IndividualLoginsInsightsQuery'
import { query as individualOnboardingQuery } from '../../queries/IndividualOnboardingInsightsQuery'
import { query as stbEscalationsQuery } from '../../queries/StbEscalationsInsightsQuery'
import { query as summaryQuestionnairesQuery } from '../../queries/SummaryQuestionnairesInsightsQuery'
import ClinicianLoginsInsightsGraph from '../ClinicianLoginsInsightsGraph'
import IndividualLoginsInsightsGraph from '../IndividualLoginsInsightsGraph'
import IndividualOnboardingInsightsGraph from '../IndividualOnboardingInsightsGraph'
import StbEscalationsInsightsGraph from '../StbEscalationsGraph'
import SummaryQuestionnairesInsightsGraph from '../SummaryQuestionnairesInsightsGraph'

type OverviewProps = {
  queryVariables: QueryVariableType,
}

const Overview = (props: OverviewProps) => {
  const { queryVariables } = props

  const { currentUser, hasOwnerRole } = useCurrentUserRoles()

  const [
    individualOnboardingQueryReference,
    loadIndividualOnboardingQuery,
  ] = useQueryLoader(individualOnboardingQuery)

  const [
    stbEscalationsQueryReference,
    loadStbEscalationsQuery,
  ] = useQueryLoader(stbEscalationsQuery)

  const [
    summaryQuestionnairesQueryReference,
    loadSummaryQuestionnairesQuery,
  ] = useQueryLoader(summaryQuestionnairesQuery)

  const [
    clinicianLoginsQueryReference,
    loadClinicianLoginsQuery,
  ] = useQueryLoader(clinicianLoginsQuery)

  const [
    individualLoginsQueryReference,
    loadIndividualLoginsQuery,
  ] = useQueryLoader(individualLoginsQuery)

  // Load query when query variables change from interval selector
  useEffect(
    () => {
      if (!currentUser.loggedIn) return

      if (hasOwnerRole && !queryVariables.owner_tenant_id) return

      loadIndividualOnboardingQuery(queryVariables)
      loadStbEscalationsQuery(queryVariables)
      loadSummaryQuestionnairesQuery(queryVariables)
      loadClinicianLoginsQuery(queryVariables)
      loadIndividualLoginsQuery(queryVariables)
    },
    [queryVariables],
  )

  const queryReady =
    individualLoginsQueryReference &&
    clinicianLoginsQueryReference &&
    summaryQuestionnairesQueryReference &&
    stbEscalationsQueryReference &&
    individualOnboardingQueryReference

  return (
    <>
      {queryReady && (
        <>
          <Box marginY="sm" minHeight="500px">
            <FlexContainer gap="xs" wrap="nowrap">
              {individualOnboardingQueryReference && (
                <FlexItem flex={1}>
                  <Suspense>
                    <IndividualOnboardingInsightsGraph
                      queryVariables={queryVariables}
                      queryReference={individualOnboardingQueryReference}
                      loadIndividualOnboardingQuery={
                        loadIndividualOnboardingQuery
                      }
                    />
                  </Suspense>
                </FlexItem>
              )}

              {individualLoginsQueryReference && (
                <FlexItem flex={1}>
                  <Suspense>
                    <IndividualLoginsInsightsGraph
                      queryVariables={queryVariables}
                      queryReference={individualLoginsQueryReference}
                      loadIndividualLoginsQuery={loadIndividualLoginsQuery}
                    />
                  </Suspense>
                </FlexItem>
              )}
            </FlexContainer>
          </Box>

          {summaryQuestionnairesQueryReference && (
            <Box marginY="sm" minHeight="500px">
              <Suspense>
                <SummaryQuestionnairesInsightsGraph
                  queryVariables={queryVariables}
                  queryReference={summaryQuestionnairesQueryReference}
                  loadSummaryQuestionnairesQuery={
                    loadSummaryQuestionnairesQuery
                  }
                />
              </Suspense>
            </Box>
          )}

          <Box marginY="sm" minHeight="500px">
            <FlexContainer gap="xs" wrap="nowrap">
              {clinicianLoginsQueryReference && (
                <FlexItem flex={1}>
                  <Suspense>
                    <ClinicianLoginsInsightsGraph
                      queryVariables={queryVariables}
                      queryReference={clinicianLoginsQueryReference}
                      loadClinicianLoginsQuery={loadClinicianLoginsQuery}
                    />
                  </Suspense>
                </FlexItem>
              )}

              {stbEscalationsQueryReference && (
                <FlexItem flex={1}>
                  <Suspense>
                    <StbEscalationsInsightsGraph
                      queryVariables={queryVariables}
                      queryReference={stbEscalationsQueryReference}
                      loadStbEscalationsQuery={loadStbEscalationsQuery}
                    />
                  </Suspense>
                </FlexItem>
              )}
            </FlexContainer>
          </Box>
        </>
      )}

      {!queryReady && <LoadingSpinner />}
    </>
  )
}

export default Overview
