// @flow

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns'
import useRouter from 'found/useRouter'

import { useCurrentUserRoles } from 'react-ui/hooks/useCurrentUserRoles'
import { PageHeader } from 'platform_web/components'
import { Box, Callout, Container, SuspenseLoader, Tabs } from 'care-ui'
import alertInfo from 'care-ui/atoms/icons/alert-info.svg'

import Outcomes from './components/Outcomes'
import OutcomesHeaderActions from './components/OutcomesHeaderActions'
import Overview from './components/Overview'
import InsightsPageHeaderActions from './components/PageHeaderActions'
import useInsights from './hooks/useInsights'
import useOutcomeReporting from './hooks/useOutcomeReporting'

const TABS = {
  OVERVIEW: 'Overview',
  OUTCOMES: 'Outcomes',
}

const defaultQueryVariables = {
  interval: 'monthly',
  start_date: format(startOfMonth(subMonths(new Date(), 5)), 'yyyy-MM-dd'),
  end_date: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
}

const Insights = () => {
  // For owner role, we need to set default tenant_id filter
  const { currentUser, ownerTenants } = useCurrentUserRoles()
  const ownerDefaultTenantId = ownerTenants?.[0]?.id

  const useInsightsPayload = useInsights({ defaultQueryVariables })

  const { queryVariables, setQueryVariables } = useInsightsPayload

  const {
    selectedTab,
    setSelectedTab,
    displayOutcomeReporting,
  } = useOutcomeReporting()

  const { interval = 'monthly' } = queryVariables

  const { router } = useRouter()
  // Feature Toggle
  const displayNewInsights = window.GLOBALS?.features?.NEW_INSIGHTS

  useEffect(() => {
    if (!displayNewInsights) {
      router.replace({
        name: 'insights',
      })
    }
  }, [])

  // Preload all the query on insights page load
  // Require current user redux state to be loaded for getting all the ownerTenants for owner roles
  useEffect(
    () => {
      if (!currentUser.loggedIn) return

      setQueryVariables({ owner_tenant_id: ownerDefaultTenantId })
    },
    [currentUser],
  )

  const { t: translation } = useTranslation('insights', {
    keyPrefix: 'insightsPage',
  })

  const calloutText = (
    <span>
      {translation('calloutMessage')} &nbsp;
      <a href="mailto:feedback@innowell.org">feedback@innowell.org</a>.
    </span>
  )

  const tabActions = {
    [TABS.OUTCOMES]: (
      <OutcomesHeaderActions
        setQueryVariables={setQueryVariables}
        queryVariables={queryVariables}
      />
    ),
    [TABS.OVERVIEW]: (
      <InsightsPageHeaderActions
        interval={interval}
        setQueryVariables={setQueryVariables}
        queryVariables={queryVariables}
      />
    ),
  }

  const tabs = [
    { label: TABS.OUTCOMES, content: <Outcomes /> },
    {
      label: TABS.OVERVIEW,
      content: <Overview queryVariables={queryVariables} />,
    },
  ]

  const renderPageAction = () => {
    return tabActions[selectedTab]
  }

  const handleTabChange = index => {
    setSelectedTab(tabs[index].label)
  }

  const currentTabContent = tabs.find(tab => tab.label === selectedTab)?.content

  return (
    <>
      <PageHeader
        pageTitle={translation('pageTitle')}
        pageAction={renderPageAction()}
      />

      <Container>
        <Box marginY="sm">
          <Callout
            calloutText={calloutText}
            variant="warning"
            calloutIcon={alertInfo}
          />
        </Box>
        {displayOutcomeReporting && (
          <Tabs tabs={tabs} defaultTab={0} onChange={handleTabChange} />
        )}
        {currentTabContent && (
          <SuspenseLoader>{currentTabContent}</SuspenseLoader>
        )}
      </Container>
    </>
  )
}

export default Insights
