// @flow

import { useState } from 'react'

export type UseInsightsReturnType = {
  displayOutcomeReporting: boolean,
  selectedTab: string,
  setSelectedTab: (selectedTab: string) => void,
}

const useOutcomeReporting = (): UseInsightsReturnType => {
  // Feature Toggle
  const displayOutcomeReporting = window.GLOBALS?.features?.OUTCOME_REPORTING

  const [selectedTab, setSelectedTab] = useState(
    displayOutcomeReporting ? 'Outcomes' : 'Overview',
  )

  return {
    selectedTab,
    setSelectedTab,
    displayOutcomeReporting,
  }
}

export default useOutcomeReporting
