// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { keys, sortBy, toPairs } from 'lodash/fp'

import { Container } from 'react-ui/components/Grid'
import { type OnboardingStepTypes } from 'react-ui/hooks/useOnboardingSteps'

type Props = {
  steps: OnboardingStepTypes,
}

const containerStyle = () => ({
  maxWidth: '65rem',
  margin: '0 auto',
})

const progressBarContainerStyle = () => ({
  display: 'flex',
  listStyleType: 'none',
  justifyContent: 'space-between',
  padding: 0,
  marginBottom: '2rem',
})

const progressBarStepsStyle = (
  theme,
  { step, completed, totalSteps, active },
) => {
  const stepWidthPercent = 12
  const lineWidthPercent =
    (100 - stepWidthPercent * totalSteps) / (totalSteps - 1)
  const lineRelativeWidth = (lineWidthPercent + 6) / stepWidthPercent * 100

  const labelContent = completed ? '"\u2713"' : `"${step}"`

  const labelBackground = completed
    ? theme.palette.section.grey.backgroundColor
    : theme.component.lightGrayBg

  let labelTextColor = theme.palette.component.muted.mutedBase
  if (active) labelTextColor = 'inherit'
  if (completed) labelTextColor = 'white'

  return {
    textAlign: 'center',
    width: `${stepWidthPercent}%`,
    position: 'relative',

    '::before': {
      content: labelContent,
      background: labelBackground,
      color: labelTextColor,
      width: '2rem',
      height: '2rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto 0.5rem auto',
      borderRadius: '50%',
      textAlign: 'center',
    },

    '::after': {
      content: '" "',
      position: 'absolute',
      width: `${lineRelativeWidth}%`,
      height: '1px',
      background: theme.palette.section.grey.backgroundColor,
      top: '1rem',
      left: '76%',
      zIndex: '-1',
    },

    ':last-child::after': {
      content: 'none',
    },
  }
}
const MultiStepProgressBar = ({ steps }: Props) => {
  const { css, theme } = useFela()

  const totalSteps = keys(steps).length
  const stepsArray = toPairs(steps)
  const sortedStepsArray = sortBy(step => step[1].order)(stepsArray)

  return (
    <Container>
      <div className={css(containerStyle)}>
        <ul className={css(progressBarContainerStyle)}>
          {sortedStepsArray.map((step, index) => {
            const { completed, active, label } = step[1]
            return (
              <li
                key={step[0]}
                className={css(
                  progressBarStepsStyle(theme, {
                    step: index + 1,
                    completed,
                    totalSteps,
                    active,
                  }),
                )}
                data-testid="step"
              >
                {label}
              </li>
            )
          })}
        </ul>
      </div>
    </Container>
  )
}

export default MultiStepProgressBar
