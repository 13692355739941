// @flow

import React from 'react'

import { FlexContainer, GraphIntervalSelector } from 'care-ui'

import { type QueryVariableType } from '../hooks/useInsights'

import TenantSwitcher from './TenantSwitcher'

type InsightsPageHeaderActionsProps = {
  interval: string,
  queryVariables: QueryVariableType,
  setQueryVariables: (queryVariables: { [key: string]: string }) => void,
}

const InsightsPageHeaderActions = (props: InsightsPageHeaderActionsProps) => {
  const { interval, setQueryVariables, queryVariables } = props

  return (
    <FlexContainer alignItems="center" gap="xs" wrap="nowrap">
      <TenantSwitcher
        queryVariables={queryVariables}
        setQueryVariables={setQueryVariables}
      />

      <GraphIntervalSelector
        interval={interval}
        setQueryVariables={setQueryVariables}
      />
    </FlexContainer>
  )
}

export default InsightsPageHeaderActions
