// @flow

import React from 'react'
import { useLazyLoadQuery } from 'react-relay'
import { lowerCase } from 'lodash'

import { useCurrentUserRoles } from 'react-ui/hooks/useCurrentUserRoles'
import type { UseMultiSelectReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiSelect'
import { Divider, FlexContainer, ScrollableBox } from 'care-ui'

import useRequestAssessment from '../../hooks/useRequestAssessments'
import { assignableRequestAssessmentsQuery } from '../../query/AssignableRequestAssessments'

import RequestAssessmentListItem from './RequestAssessmentListItem'

type RequestAssessmentListPropsType = {
  filters: { [key: string]: any },
  multiSelectPayload: UseMultiSelectReturnTypes,
  searchString?: string,
  selectedItems: Array<{ [key: string]: string }>,
  toggleSelectedItems: (item: { id: string, label: string }) => void,
}

const RequestAssessmentList = (props: RequestAssessmentListPropsType) => {
  const {
    searchString,
    multiSelectPayload,
    filters,
    selectedItems,
    toggleSelectedItems,
  } = props

  const { allSelected, deselectedRoleIds, selectedRoleIds } = multiSelectPayload

  const { tenant } = useCurrentUserRoles()

  const assignableRequestAssessmentsQueryVariables = {
    tenant_id: tenant.id,
    individuals: {
      dataTableType: 'individual',
      selectedRoleIds: allSelected ? [] : selectedRoleIds,
      deselectedRoleIds,
      ...(allSelected && { filters }),
    },
  }

  const queryData = useLazyLoadQuery(
    assignableRequestAssessmentsQuery,
    assignableRequestAssessmentsQueryVariables,
  )

  const assignableRequestAssessments =
    queryData?.tenant?.multi_select_requestable_questionnares

  const {
    initialRequestAssessment,
    summaryRequestAssessment,
    otherRequestAssessments,
    disableInitialRequestAssessment,
    disableSummaryRequestAssessment,
    disableOtherRequestAssessment,
  } = useRequestAssessment({
    requestAssessments: assignableRequestAssessments,
    selectedRequestAssessments: selectedItems,
  })
  const filteredOtherRequestAssessments = otherRequestAssessments?.filter(
    assignableAssessment =>
      lowerCase(assignableAssessment.questionnaire.label).includes(
        lowerCase(searchString),
      ),
  )

  const handleOnChange = (id, label) => {
    // For Chips
    toggleSelectedItems({ id, label })
  }

  return (
    <ScrollableBox maxHeight="100%" maxWidth="600px">
      {(initialRequestAssessment || summaryRequestAssessment) && (
        <FlexContainer direction="column" gap="xs" paddingX="xxs">
          {initialRequestAssessment && (
            <RequestAssessmentListItem
              requestAssessment={initialRequestAssessment}
              handleOnChange={handleOnChange}
              selectedItems={selectedItems}
              disabled={disableInitialRequestAssessment}
            />
          )}

          {summaryRequestAssessment && (
            <RequestAssessmentListItem
              requestAssessment={summaryRequestAssessment}
              handleOnChange={handleOnChange}
              selectedItems={selectedItems}
              disabled={disableSummaryRequestAssessment}
            />
          )}

          <Divider />
        </FlexContainer>
      )}

      <FlexContainer direction="column" gap="sm" wrap="nowrap">
        {filteredOtherRequestAssessments.map(assignableAssessment => (
          <RequestAssessmentListItem
            requestAssessment={assignableAssessment}
            handleOnChange={handleOnChange}
            selectedItems={selectedItems}
            disabled={disableOtherRequestAssessment(
              assignableAssessment.questionnaire.id,
            )}
          />
        ))}
      </FlexContainer>
    </ScrollableBox>
  )
}

export default RequestAssessmentList
