// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Heading, Text } from 'care-ui'

const RecoveryTrajectories = () => {
  const { t: translation } = useTranslation('insights', {
    keyPrefix: 'recoveryTrajectoriesComponent',
  })

  return (
    <Box>
      <Heading level={3}>{translation('recoveryHeading')}</Heading>
      <Text margin="xxs">{translation('recoverySubheading')}</Text>
      {/* TODO: Graph contents here */}
      <Box minHeight="300px" />
      {// TODO: Add condition to show empty state when we have data
      false && (
        <Box marginY="56px">
          <Text center margin="lg" bold>
            {translation('paragraphOne')}
          </Text>
          <Text center bold>
            {translation('paragraphTwo')}
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default RecoveryTrajectories
