// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'

import { usePolicies } from 'react-ui/hooks/usePolicies'
import Can from 'react-ui/utils/Can'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'
import { Button } from 'care-ui'

import { currentResultCardUserTrackable } from '../../queries/CurrentResultCard'
import { CurrentResultCardFormula } from '../CurrentResultCardFormula'

import {
  buttonWrapperStyle,
  currentResultCardStyle,
} from './CurrentResultCard.style'

import { type CurrentResultCard_user_trackable } from '../../queries/__generated__/CurrentResultCard_user_trackable.graphql'

type PropsType = {
  user_trackable: CurrentResultCard_user_trackable,
}

const CurrentResultCardComponent = (props: PropsType) => {
  const {
    user_trackable,
    user_trackable: { primary_user_questionnaire, suggested_user_resources },
  } = props
  const policies = usePolicies()
  const { css } = useFela()

  const { ensure_questionnaire: { id: questionnaireId } } =
    primary_user_questionnaire || {}

  const { start } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    questionnaireId,
  })

  const { t: translation } = useTranslation('scoreCard', {
    keyPrefix: 'currentResultCardPage',
  })

  if (!primary_user_questionnaire) {
    return false
  }
  const careOptionSupported = suggested_user_resources?.some(
    ({ status }) => status === 'supported',
  )
  const showNotifications =
    user_trackable.escalated &&
    user_trackable.assigned_to_me &&
    policies.CAN_VIEW_CLINICIAN_LINKS

  return (
    <div
      className={css(currentResultCardStyle)}
      data-testid="CurrentResultCard"
    >
      <CurrentResultCardFormula
        careOptionSupported={careOptionSupported}
        user_trackable={user_trackable}
      />

      {!showNotifications && (
        <Can>
          {({ CAN_CREATE_USER_QUESTIONNAIRE: canAnswerQuestions }) => (
            <div className={css(buttonWrapperStyle)}>
              <Button
                variant="primary"
                dataTestId="trackable-questions"
                disabled={
                  !canAnswerQuestions || !user_trackable.matches_user_age
                }
                onClick={start}
                ariaLabel={translation('retakeQuestionnaire')}
              >
                {translation('retakeQuestionnaire')}
              </Button>
            </div>
          )}
        </Can>
      )}
    </div>
  )
}

export const CurrentResultCard = createFragmentContainer(
  CurrentResultCardComponent,
  currentResultCardUserTrackable,
)
