// @flow

import React from 'react'
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay'
import { map } from 'lodash'

import { useCurrentUserRoles } from 'react-ui/hooks/useCurrentUserRoles'
import type { UseMultiRoleAssignmentReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiRoleAssignment'
import type { UseMultiSelectReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiSelect'
import { type QueryVariablesType } from 'platform_web/pages/Staff/Individuals/hooks/useIndividualTable'
import { Checkbox, FlexContainer, ScrollableBox } from 'care-ui'

import {
  assignableIndividualsFragment,
  assignableIndividualsQuery,
} from '../../query/AssignableIndividuals'

const COUNT = 25

type AssignableIndividualsListPropsType = {
  multiSelectPayload: UseMultiSelectReturnTypes,
  queryVariables: QueryVariablesType,
  searchString?: string,
  toggleSelectedIndividual: (individual: { id: string, label: string }) => void,
  useMultiRoleAssignmentPayload: UseMultiRoleAssignmentReturnTypes,
}

const AssignableIndividualsList = (
  props: AssignableIndividualsListPropsType,
) => {
  const {
    searchString,
    multiSelectPayload,
    queryVariables,
    toggleSelectedIndividual,
    useMultiRoleAssignmentPayload,
  } = props

  const { allSelected, deselectedRoleIds, selectedRoleIds } = multiSelectPayload

  const { tenant } = useCurrentUserRoles()

  const filters = {
    search: String(queryVariables?.search),
    roleTypes: ['CLINICIAN'],
  }

  const assignableIndividualsQueryVariables = {
    search: searchString,
    count: COUNT,
    tenant_id: tenant.id,
    clinicians: {
      dataTableType: 'clinician',
      selectedRoleIds: allSelected ? [] : selectedRoleIds,
      deselectedRoleIds,
      ...(allSelected && { filters }),
    },
  }

  const queryData = useLazyLoadQuery(
    assignableIndividualsQuery,
    assignableIndividualsQueryVariables,
  )

  const { data, loadNext, hasNext } = usePaginationFragment(
    assignableIndividualsFragment,
    queryData?.viewer,
  )

  const assignableIndividuals = map(
    data.tenant.multi_select_assignable_individuals.edges,
    'node',
  )

  const {
    assignedRoleIds,
    toggleAssignedRoleIds,
  } = useMultiRoleAssignmentPayload

  const handleOnChange = (id, label) => {
    toggleAssignedRoleIds(id)

    // For Chips
    toggleSelectedIndividual({ id, label })
  }

  return (
    <ScrollableBox
      maxHeight="100%"
      maxWidth="500px"
      onScroll={hasNext ? () => loadNext(COUNT) : undefined}
    >
      <FlexContainer direction="column" gap="sm" wrap="nowrap">
        {assignableIndividuals?.map(assignableIndividual => {
          const { id, user: { name } } = assignableIndividual
          const isSelected = assignedRoleIds.includes(id)

          return (
            <Checkbox
              dataTestId="individual"
              inputAttributes={{
                id,
                name: id,
                label: name,
                onChange: () => handleOnChange(id, name),
                value: isSelected,
              }}
            />
          )
        })}
      </FlexContainer>
    </ScrollableBox>
  )
}

export default AssignableIndividualsList
