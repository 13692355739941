// @flow

// We have to move this to template folder

import React from 'react'

import { useCurrentUserRoles } from 'react-ui/hooks/useCurrentUserRoles'
import { truncateText } from 'platform_web/utility/formatText'
import { Box, SimpleSelect, Text } from 'care-ui'

import { type QueryVariableType } from '../hooks/useInsights'

type TenantSwitcherProps = {
  queryVariables: QueryVariableType,
  setQueryVariables: (queryVariables: { [key: string]: string }) => void,
}

const TenantSwitcher = (props: TenantSwitcherProps) => {
  const { setQueryVariables, queryVariables = {} } = props

  const { ownerTenants, hasOwnerRole } = useCurrentUserRoles()

  if (!hasOwnerRole) return null

  const { owner_tenant_id } = queryVariables

  const tenantSwitcherOptions = ownerTenants.map(tenant => ({
    label: tenant.name,
    value: tenant.id,
  }))

  const handleOnChange = value => {
    setQueryVariables({ owner_tenant_id: value })
  }

  const hasMoreThanOneTenant = ownerTenants.length > 1

  return (
    <>
      {hasMoreThanOneTenant && (
        <Box width="220px">
          <SimpleSelect
            dataTestIdForDropdown="tenantSwitcher"
            dataTestIdForDropdownOptions="tenant"
            options={tenantSwitcherOptions}
            onChange={handleOnChange}
            truncateLabel
            {...owner_tenant_id && {
              defaultOption: tenantSwitcherOptions.find(
                option => option.value === owner_tenant_id,
              ),
            }}
          />
        </Box>
      )}

      {!hasMoreThanOneTenant && (
        <Text size="md">{truncateText(ownerTenants?.[0]?.name)}</Text>
      )}
    </>
  )
}

export default TenantSwitcher
