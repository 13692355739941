// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Section from 'react-ui/components/Section'
import useChartToggle from 'react-ui/hooks/useChartToggle'
import { Button } from 'care-ui'

import { buttonContainerRules } from './DownloadReportTaskButton.style'

const DownloadReportTaskButton = () => {
  const { isSummaryGraph } = useChartToggle()
  const [isIndividual] = useSelector(({ currentUser: { roleType } }) => [
    roleType === 'INDIVIDUAL',
  ])

  const { t: translation } = useTranslation('shared', {
    keyPrefix: 'healthHistoryPage.downloadReportTaskButton',
  })

  if (!isSummaryGraph || !isIndividual) return null

  const handleClick = () => {
    const newWindow = window.open(
      '/individual/emr',
      '_blank',
      'noopener,noreferrer',
    )
    if (newWindow) newWindow.opener = null
  }

  return (
    <Section extend={buttonContainerRules} noPadding>
      <Button
        onClick={handleClick}
        variant="text"
        ariaLabel={translation('reportLabel')}
        dataTestId="report"
      >
        <span>{translation('reportLabel')}</span>
      </Button>
    </Section>
  )
}

export default DownloadReportTaskButton
