// @flow

import React from 'react'

import { Box, FlexContainer, SimpleSelect } from 'care-ui'

import { type QueryVariableType } from '../hooks/useInsights'

import TenantSwitcher from './TenantSwitcher'

type OutcomesHeaderActionsProps = {
  queryVariables: QueryVariableType,
  setQueryVariables: (queryVariables: { [key: string]: string }) => void,
}

const OutcomesHeaderActions = (props: OutcomesHeaderActionsProps) => {
  const { setQueryVariables, queryVariables } = props

  // Dummy Date selector data
  const INTERVALS = [
    { value: '2024', label: '2024-23' },
    { value: '2023', label: '2023-22' },
  ]

  return (
    <FlexContainer alignItems="center" gap="xs" wrap="nowrap">
      <TenantSwitcher
        queryVariables={queryVariables}
        setQueryVariables={setQueryVariables}
      />
      <Box width="220px">
        <SimpleSelect
          options={INTERVALS}
          defaultOption={INTERVALS[0]}
          onChange={() => {}}
          name="interval"
          dataTestIdForDropdown="intervalSelector"
          dataTestIdForDropdownOptions="intervalOptions"
        />
      </Box>
    </FlexContainer>
  )
}

export default OutcomesHeaderActions
