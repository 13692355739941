// @flow

// Insights Pages
import clinicianLoginsInsightsGraphTranslations from 'platform_web/pages/Insights/components/ClinicianLoginsInsightsGraph/localizedStrings.json'
import individualLoginsInsightsGraphTranslations from 'platform_web/pages/Insights/components/IndividualLoginsInsightsGraph/localizedStrings.json'
import individualOnboardingTranslations from 'platform_web/pages/Insights/components/IndividualOnboardingInsightsGraph/localizedStrings.json'
import baselineScoresTranslations from 'platform_web/pages/Insights/components/Outcomes/BaselineScores/localized.json'
import emptyOutcomeStateTranslations from 'platform_web/pages/Insights/components/Outcomes/EmptyOutcomeState/localized.json'
import recoveryTrajectoriesTranslations from 'platform_web/pages/Insights/components/Outcomes/RecoveryTrajectories/localized.json'
import stbEscalationsTranslations from 'platform_web/pages/Insights/components/StbEscalationsGraph/localizedStrings.json'
import summaryQuestionnairesInsightsGraphTranslations from 'platform_web/pages/Insights/components/SummaryQuestionnairesInsightsGraph/localizedStrings.json'
import insightsNewTranslations from 'platform_web/pages/Insights/localizedStrings.json'

const insightsTranslations = {
  en: {
    emptyOutcomeStateComponent: emptyOutcomeStateTranslations.en,
    recoveryTrajectoriesComponent: recoveryTrajectoriesTranslations.en,
    baselineScoresComponent: baselineScoresTranslations.en,
    clinicianLoginsIsightsGraphComponent:
      clinicianLoginsInsightsGraphTranslations.en,
    individualLoginsIsightsGraphComponent:
      individualLoginsInsightsGraphTranslations.en,
    individualOnboardingIsightsGraphComponent:
      individualOnboardingTranslations.en,
    insightsPage: insightsNewTranslations.en,
    stbEscalationsIsightsGraphComponent: stbEscalationsTranslations.en,
    summaryQuestionnairesInsightsGraphComponent:
      summaryQuestionnairesInsightsGraphTranslations.en,
  },
  fr: {
    emptyOutcomeStateComponent: emptyOutcomeStateTranslations.fr,
    recoveryTrajectoriesComponent: recoveryTrajectoriesTranslations.fr,
    baselineScoresComponent: baselineScoresTranslations.fr,
    clinicianLoginsIsightsGraphComponent:
      clinicianLoginsInsightsGraphTranslations.fr,
    individualLoginsIsightsGraphComponent:
      individualLoginsInsightsGraphTranslations.fr,
    individualOnboardingIsightsGraphComponent:
      individualOnboardingTranslations.fr,
    insightsPage: insightsNewTranslations.fr,
    stbEscalationsIsightsGraphComponent: stbEscalationsTranslations.fr,
    summaryQuestionnairesInsightsGraphComponent:
      summaryQuestionnairesInsightsGraphTranslations.fr,
  },
}

export default insightsTranslations
