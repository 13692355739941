// @flow

import React from 'react'

import { Box } from 'care-ui'

import BaselineScores from './BaselineScores'
import EmptyOutcomeState from './EmptyOutcomeState'
import RecoveryTrajectories from './RecoveryTrajectories'

const Outcomes = () => {
  return (
    // Need to add condition when to show empty State
    <Box paddingX="38px" maxWidth="1155px" marginX="auto">
      {false && (
        <>
          <RecoveryTrajectories />
          <BaselineScores />
        </>
      )}
      {true && <EmptyOutcomeState />}
    </Box>
  )
}

export default Outcomes
