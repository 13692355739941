// @flow

import { useSetState } from 'react-use'

export type QueryVariableType = {
  end_date?: string,
  interval?: string,
  owner_tenant_id?: string,
  start_date?: string,
}
type UseInsightsParams = {
  defaultQueryVariables: QueryVariableType,
}

export type UseInsightsReturnType = {
  queryVariables: QueryVariableType,
  setQueryVariables: (queryVariables: QueryVariableType) => void,
}

const useInsights = (params: UseInsightsParams): UseInsightsReturnType => {
  const { defaultQueryVariables } = params

  const [queryVariables, setQueryVariables] = useSetState(defaultQueryVariables)

  return {
    queryVariables,
    setQueryVariables,
  }
}

export default useInsights
