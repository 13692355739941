// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { type PreloadedQuery, usePreloadedQuery } from 'react-relay'
import { get } from 'lodash'

import { Graph, GraphContainer } from 'care-ui'
import { navy } from 'care-ui/atoms/colors/colorTokens'

import useGraphPagination from '../../hooks/useGraphPagination'
import { type QueryVariableType } from '../../hooks/useInsights'
import { query } from '../../queries/StbEscalationsInsightsQuery'

import { type StbEscalationsInsightsQuery } from '../../queries/__generated__/StbEscalationsInsightsQuery.graphql'

type StbEscalationsInsightsGraphProps = {
  loadStbEscalationsQuery: (queryVariables: QueryVariableType) => void,
  queryReference: PreloadedQuery<StbEscalationsInsightsQuery>,
  queryVariables: QueryVariableType,
}

const StbEscalationsInsightsGraph = (
  props: StbEscalationsInsightsGraphProps,
) => {
  const { queryVariables, queryReference, loadStbEscalationsQuery } = props
  const queryResult = usePreloadedQuery(query, queryReference)

  const seriesCategories = get(
    queryResult,
    'viewer.insights.stb_escalations.series_categories',
  )
  const series = get(queryResult, 'viewer.insights.stb_escalations.series')

  // Pagination
  const { onPaginate, isRightPaginationDisabled } = useGraphPagination({
    queryVariables,
    loadQuery: loadStbEscalationsQuery,
  })

  const { t: translation } = useTranslation('insights', {
    keyPrefix: 'stbEscalationsIsightsGraphComponent',
  })

  return (
    <GraphContainer
      heading={translation('graphHeading')}
      graphInfoText={translation('graphText')}
    >
      {series && (
        <Graph
          colors={[navy[400], navy[500]]}
          graphType="column"
          series={series}
          seriesCategories={seriesCategories}
          onPaginateLeft={() => onPaginate('left')}
          onPaginateRight={() => onPaginate('right')}
          isRightPaginationDisabled={isRightPaginationDisabled}
        />
      )}
    </GraphContainer>
  )
}

export default StbEscalationsInsightsGraph
