// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import { FlexContainer, Text } from 'care-ui'

const EmptyOutcomeState = () => {
  const { t: translation } = useTranslation('insights', {
    keyPrefix: 'emptyOutcomeStateComponent',
  })

  return (
    <FlexContainer justifyContent="center">
      <Text center bold>
        {translation('paragraphOne')}
      </Text>
      <Text center margin="md" bold>
        {translation('paragraphTwo')}
      </Text>
      <Text center bold>
        {translation('paragraphThree')}
      </Text>
    </FlexContainer>
  )
}

export default EmptyOutcomeState
