// @flow

import { useCurrentUserRoles } from 'react-ui/hooks/useCurrentUserRoles'

type RoleType = 'MANAGER'

type TableType = 'Active' | 'Invited' | 'Removed'

type ACTIONS_FOR_CLINICIAN_TYPE = Array<{
  actionName: string,
  roles: Array<RoleType>,
  tableTypes: Array<TableType>,
}>

const ACTIONS_FOR_CLINICIAN: ACTIONS_FOR_CLINICIAN_TYPE = [
  {
    actionName: 'removeRole',
    tableTypes: ['Active', 'Invited'],
    roles: ['MANAGER'],
  },
  {
    actionName: 'resendRoleInvitation',
    tableTypes: ['Invited', 'Removed'],
    roles: ['MANAGER'],
  },
]

type UseEmployessActionProps = {
  tableType: TableType | string,
}
const useEmployeesActions = (props: UseEmployessActionProps) => {
  const { tableType } = props

  const { roleType } = useCurrentUserRoles()

  const getAllowedActions = () => {
    const allowedActions: Array<string> = ACTIONS_FOR_CLINICIAN.filter(
      action =>
        action.tableTypes.includes(tableType) &&
        action.roles.includes(roleType),
    ).map(action => action.actionName)

    return allowedActions
  }

  return {
    actions: getAllowedActions(),
  }
}

export default useEmployeesActions
