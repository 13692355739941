// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useFragment } from 'react-relay'
import { filter, orderBy } from 'lodash/fp'

import { usePolicies } from 'react-ui/hooks/usePolicies'
import { hexToHue } from 'shared/services/colorConverter'

import HealthCardList from '../HealthCardList'
import HealthPriorityList from '../HealthPriorityList'

import { containerStyle } from './HealthCardContainer.style'
import { healthCardContainerFragment } from './query/HealthCardContainer'

import type { HealthCardContainer_user$key } from './query/__generated__/HealthCardContainer_user.graphql'

type PropsType = {
  +user: HealthCardContainer_user$key,
}

const HealthCardContainer = (props: PropsType) => {
  const { user } = props
  const { extensiveUserTrackables, linked_individual } = useFragment(
    healthCardContainerFragment,
    user,
  )

  const isReadOnlyIndividual = linked_individual?.individual_detail?.read_only
  const policies = usePolicies()
  const { css } = useFela()

  if (!extensiveUserTrackables) {
    return ''
  }

  const hueSorter = node => {
    // get hue from hex
    const hue = node.formula_range.color
      ? hexToHue(node.formula_range.color)
      : 0

    // hue higher than 330 is still red and considered priority color
    return hue >= 330 ? hue - 330 : hue
  }

  const trackableLabelSorter = 'trackable.label'

  const userTrackables = orderBy(
    [hueSorter, trackableLabelSorter],
    ['asc', 'asc'],
  )(extensiveUserTrackables.nodes)

  return (
    <div className={css(containerStyle)}>
      <HealthPriorityList
        user_trackables={filter(['in_plan', true])(userTrackables)}
        viewOnly={isReadOnlyIndividual || !policies.CAN_UPDATE_USER_TRACKABLES}
      />
      <HealthCardList
        user_trackables={filter(['in_plan', false])(userTrackables)}
      />
    </div>
  )
}

export default HealthCardContainer
