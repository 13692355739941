// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, FlexContainer, Heading, IconNew, Text, Tooltip } from 'care-ui'
import { InfoIcon } from 'care-ui/atoms/icons-new'

const BaselineScores = () => {
  const { t: translation } = useTranslation('insights', {
    keyPrefix: 'baselineScoresComponent',
  })

  return (
    <Box marginY="40px">
      <FlexContainer alignItems="center">
        <Heading level={3}>{translation('baselineHeading')}</Heading>
        <Box marginX="xxs">
          <Text bold size="lg">
            (n = 466)
          </Text>
        </Box>
      </FlexContainer>
      <FlexContainer paddingX="xxs" alignItems="center">
        <Text>{translation('baselineSubheading')}</Text>
        <Box marginX="xxs">
          <Tooltip text={translation('tooltipText')} variant="info">
            <IconNew as={InfoIcon} size="md" />
          </Tooltip>
        </Box>
      </FlexContainer>
      {/* TODO: Graph contents here */}
      <Box minHeight="300px" />
    </Box>
  )
}

export default BaselineScores
